import { ImageBitmapLoader, LinearMipmapLinearFilter, SRGBColorSpace, Texture } from 'three';

export const loadBitmapTexture = (
    url,
    { flipY = false, minFilter = LinearMipmapLinearFilter } = {}
) => {
    const loader = new ImageBitmapLoader();

    if (!flipY) loader.setOptions({ imageOrientation: 'flipY' });

    return new Promise((resolve, reject) => {
        loader.load(
            url,
            (imageBitmap) => {
                const texture = new Texture(imageBitmap);
                texture.colorSpace = SRGBColorSpace;
                texture.flipY = false;
                texture.minFilter = minFilter;
                texture.needsUpdate = true;

                resolve(texture);
            },
            undefined,
            (err) => {
                // reject(err);

                console.warn(err);

                resolve(new Texture());
            }
        );
    });
};
